import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components/macro'
import shevron from './assets/shevron.svg'

const CustomSelectContainer = styled.div`
    position: relative;
    width:  80px;
    cursor: pointer;
    border: 1px solid #949494;
    height: 35px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding-left: 8px;
`

const DropDown = styled.ul`
   position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 200px; 
    overflow-y: auto;
    background: white;
    border: 1px solid #949494;
    border-radius: 10px;
    display: block;
    z-index: 99999;
    overflow: auto;
    scrollbar-width: none; 
    ::-webkit-scrollbar {
        display: none; /* Для Chrome, Safari */
        }

    li {
        padding: 8px;
    }

    li:hover {
        background:rgba(255, 232, 217, 1)
    }
`

const Shevron = styled.img`
    position: absolute;
    top: 7px;
    right: 7px;
`


export const CustomSelect = (props) => {
    const [isOpenList, setIsOpenList] = useState(false)
    const selectRef = useRef(null); 
    const { children, value } = props;

    const onClickHandler = () => {
        setIsOpenList((prev) => !prev);
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (selectRef.current && !selectRef.current.contains(event.target)) {
                setIsOpenList(false);
            }
        };

        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    return (
        <CustomSelectContainer ref={selectRef} onClick={onClickHandler} >
            <Shevron src={shevron} alt='shevron'/>
            <div>{value}</div>
            {isOpenList &&
                <DropDown>
                    {children}
                </DropDown>
            }
        </CustomSelectContainer>
    )
}