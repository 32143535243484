import React, {useState, useEffect, useCallback} from 'react'
import styled from 'styled-components/macro'
import axios from '../../../utils/axios'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import defaultToy from './../../../assets/defaultToy.png'
import heartImg from './icons/heart.svg'

import EditIcon from './icons/edit.svg';
import DeleteIcon from './../../../assets/deleteIcon.svg';
import ConfirmModal from '../../confirm-modal/confirm-modal'
import { getCostWithDiscount } from '../../../utils/getCostWithDiscount'

const Section = styled.section`
width: 900px;
display: grid;
grid-template-columns: 220px 220px 220px 220px;
margin-top: -2rem;

@media (max-width: 577px){
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-top: 40px;
    justify-content: center;
    gap: 25px;
}
`
const WorkItem = styled.div`
height: 300px;
width: 200px;
border: 1px solid rgba(171, 154, 144, 0.3);
border-radius: 10px;
margin: 0.5rem -0.2rem;

@media (max-width: 577px){
    width: 170px;
    height: 300px;
    margin-left: 0;
}
`
const ImgCont = styled.div`

img{
    height: 240px;
    width: 190px;
    margin: 0.4rem 0.3rem;
    border-radius: 4px;
    object-fit: cover;
}

@media (max-width: 577px){
    img{
        width: 160px;
    }
}
`
const TextCont = styled.div`
margin-top: -0.5rem;

@media (max-width: 577px){
    
}
`
const Name = styled.div`
font-family: 'Montserrat';
font-weight: 500;
font-size: 12pt;
display: block;
margin-left: 7px;
text-transform: capitalize;
color: #917272;
margin-top: 0;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;

@media (max-width: 577px){
}
`

const Likes = styled.div`
opacity: 0.8;
width: 70px;
height: 30px;
font-weight: 400;
display: flex;
align-items: center;

img {
 width: 13px;
 display: block;
}

div {
font-size: 16px;
}

@media (max-width: 577px){
    
}
`

const Status = styled.div`
font-weight: 500;
color: #D8946B;
white-space: nowrap;

@media (max-width: 577px){
    margin-left: 1.5rem;
    font-size: 13px;
    margin-top: -.1rem;
}
`
const FilterButton = styled.button`
    margin: 3rem 1rem;
    width: 175px;
    height: 40px;
    background: ${props => props.active ? 'rgba(213, 200, 200, 0.6)' : '#F5F5F5'};
    border: 1px solid ${props => props.active ? '#D5C8C8' : '#E1DFD2'};
    border-radius: 10px;
    font-family: 'Palatino';
    font-size: 18px;
    padding: -0,5rem 1rem;
    text-transform: capitalize;
    cursor: pointer;

    span {
        margin: 0;
        font-size: 14px;
    }

    @media (max-width: 577px){
        margin: 0 0 5px 0;
    }
    @media (max-width: 380px){
        width: 150px;
        margin: 0;
    }
`

const FilterButtonWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 577px) {
        gap: 0 5px;
        justify-content: center;
    }
`

const NameRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 7px;
`
const EditBtn = styled(Link)`
    width: 18px;
    height: 18px;
`;

const EditBtnWrapper = styled.div`
    display: flex;
`

const DeleteBtn = styled.div`
    
    margin-right: 13px;
    img {
        display: block;
        width: 18px;
        height: 18px;
    }
`
const StatusWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
`
const CardBlock = () => {
    const { t } = useTranslation()
    const [cards, setCards] = useState([]);
    const [filterStatus, setFilterStatus] = useState('');
    const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
    const [idForDelete, setIdForDelete] = useState(null);

    const fetchMyCards = useCallback(async () => {
        try {
            const { data } = await axios.get('/market/user/me', filterStatus && { params: { status: filterStatus } })
            setCards(data)
        } catch (error) {
            console.log(error)
        }
    }, [filterStatus])

    useEffect(() => {
        fetchMyCards()
    }, [fetchMyCards])

    if (!cards){
        return(
        <div>Загрузка</div>)
    }  
    const deleteWork = async (id, e) => {
        e.preventDefault();
        e.stopPropagation();
        
        await axios.delete(`/market/${id}`, id);
        setCards((prev) => prev.filter(({ _id }) => _id !== id))
        setIsShowDeleteModal(false)
    }

    const showDeleteModal =  (e, id) => {
        e.preventDefault();
        e.stopPropagation();
        setIsShowDeleteModal(true)
        setIdForDelete(id)
    }
 
  return (
    <>
        <FilterButtonWrapper>
            <FilterButton active={filterStatus === ''} onClick={() => setFilterStatus('')}>{t('myWorkAll')}</FilterButton>
            <FilterButton active={filterStatus === '1'} onClick={() => setFilterStatus('1')}>{t('forSale')}</FilterButton>
            <FilterButton active={filterStatus === '2'} onClick={() => setFilterStatus('2')}>{t('myWorkSold')}</FilterButton>
            <FilterButton active={filterStatus === '3'} onClick={() => setFilterStatus('3')}>{t('reserve')}</FilterButton> 
        </FilterButtonWrapper>
        <Section>
            <>
                {cards?.filter((card) => card!== null).sort((a,b) =>  b.createdAt.localeCompare(a.createdAt)).map((card) =>(
                    <Link to={`/card/${card._id}`}>
                        {/* <Card name={card.name} img={`https://toobears.com/api/${card.imgUrl[0]}`} price={card.price} likeCount={card.likeCount} key={index}/> */}
                        <WorkItem>
                            <ImgCont>
                                <img src={card.imgUrl === null ? defaultToy : `https://toobears.com/api/${card.imgUrl[0]}`} alt='card' />
                            </ImgCont>
                            <TextCont>
                                <NameRow>
                                    <Name>
                                        {card.name}
                                    </Name>
                                    <EditBtnWrapper>
                                        <DeleteBtn onClick={(e) => showDeleteModal(e, card._id)}>
                                            <img src={DeleteIcon} alt="delete" />
                                        </DeleteBtn>
                                        <EditBtn to={`/account/edit/${card._id}`}>
                                            <img src={EditIcon} alt="edit" />
                                        </EditBtn>
                                    </EditBtnWrapper>
                                </NameRow>
                                <StatusWrapper>
                                    <Likes><img src={heartImg} alt='heart'/><div>{card.likeCount}</div></Likes>
                                    {card.status === '1' || card.status.toLowerCase() === 'на продажу'
                                        ? <Status>{card.discount ? getCostWithDiscount(card.price, card.discount, false, card.currency) :  (card.price + ' ' + card.currency)}</Status>
                                        : (card.status === '3' || card.status.toLowerCase() === 'резерв'
                                            ? <Status style={{color: '#3D488B', opacity: '0.6', textTransform:'lowercase'}}>{t('reserved')}</Status>
                                            : <Status style={{color: '#000', opacity:'0.7', textTransform:'lowercase'}}>{t('soldOut')}</Status>)
                                    }
                                    
                                </StatusWrapper>
                            </TextCont>
                        </WorkItem>
                    </Link>
                ))}
                {isShowDeleteModal && 
                    <ConfirmModal 
                        onConfirm={(e) => deleteWork(idForDelete, e)}
                        onClose={() => setIsShowDeleteModal(false)} 
                        title={t('SureDelete')} 
                        acceptBtn={t('Delete')} 
                        cancelBtn={t('Cancel')}
                    />
                }
            </>
        </Section>
    </>
  )
}

export default CardBlock