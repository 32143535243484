export const getCostWithDiscount =  (fullPrice, discount, withCurrency, currency) => {
    if (withCurrency) {
        const clearPrice = fullPrice.split(' ')[0]
        const currencyPrice = fullPrice.split(' ')[1]
        const getCostWithDiscount =  Number(clearPrice) - (Number(clearPrice) * Number(discount) / 100);
        const getPriceWithDiscount = String(getCostWithDiscount) + ' ' + currencyPrice

        return getPriceWithDiscount
    } else {
        const getCostWithDiscount =  Number(fullPrice) - (Number(fullPrice) * Number(discount) / 100);
        const getPriceWithDiscount = String(getCostWithDiscount) + ' ' + currency

        return getPriceWithDiscount
    }
}