import styled from 'styled-components/macro';
import banner from './assets/banner.png';
import bannerMobile from './assets/banner-mobile.png';

export const Root = styled.div`
    box-sizing: border-box;
    padding: 0 180px;
    max-width: 1920px;
    margin: 0 auto;

    @media (max-width: 1024px){
        padding: 0 111px;
    }

    @media (max-width: 577px){
        padding: 0 40px;
    }
    
    @media (max-width: 414px){
        padding: 0 20px;
    }
`

export const TitleWrapper = styled.div`
    margin-top: 100px;
    
    @media (max-width: 414px){
        margin-top: 20px;
    }
`

export const BannerBear = styled.div`
    background: url(${banner}) no-repeat;
    max-width: 800px;
    height: 500px;
    background-position: center;
    background-size: contain;
    margin: 0 auto 9px;


    @media (max-width: 1024px){
        background: url(${banner}) no-repeat;
        background-position: center;
        background-size: contain;
        height: 340px;
        margin-bottom: 20px;
    }
    
    @media (max-width: 517px){
        background: url(${bannerMobile}) no-repeat;
        background-position: center;
        background-size: contain;
        margin-top: -30px;
        margin-bottom: 0;
        margin-bottom: 35px;
    }
`

export const MainText = styled.div`
    font-family: Palatino Linotype;
    opacity: 0.8;
    font-size: 30px;
    font-weight: 400;
    line-height: 35px; 
    margin-bottom: 50px;
    text-align: center;

    @media (max-width: 1024px){
        font-size: 25px;
        line-height: 30px; 
        margin-bottom: 24px;
    }
    
    @media (max-width: 517px){
        font-size: 20px;
        line-height: 20px; 
        margin-bottom: 20px;
    }
`

export const MainTextTitle = styled.span`
    color: rgba(165, 66, 201, 0.8);
    font-family: Palatino Linotype;
    font-size: 35px;
    font-weight: 700;
    line-height: 35px;

    @media (max-width: 1024px){
        font-size: 25px;
        line-height: 30px; 
    }
    
    @media (max-width: 414px){
        font-size: 20px;
        line-height: 20px; 
    }
`

export const Title = styled.div`
    color: #E94751;
    font-family: Palatino Linotype;
    font-size: 48px;
    font-weight: 400;
    line-height: 60px;
    text-align: center;
    margin-bottom: 20px;
    margin-top: -75px;

    @media (max-width: 1024px){
        font-size: 30px;
        line-height: 38px; 
        margin-bottom: 20px;
    }

    @media (max-width: 414px){
        font-size: 24px;
        line-height: 24px; 
        margin-bottom: 20px;
    }
`

export const GreetingTitle = styled.div`
    color: rgba(165, 66, 201, 0.8);
    font-family: "AcontiumNapellus";
    font-size: 40px;
    font-weight: 400;
    line-height: 42px;
    text-align: center;
    margin-bottom: 65px;

    @media (max-width: 1024px){
        font-size: 30px;
        line-height: 38px; 
        margin-bottom: 20px;
    }

    @media (max-width: 517px){
        font-size: 24px;
        line-height: 24px; 
        margin-bottom: 10px;
        margin-top: -7px;
    }
`

export const ShowList = styled.ul`
    list-style-type: decimal;
    margin-bottom: 45px;
    padding-inline-start: 27px;

    @media (max-width: 1024px){
        margin-bottom: 20px;
    }

    @media (max-width: 414px){
        padding-inline-start: 20px;
    }
`

export const ShowListItem = styled.li`
    color: #000;
    font-family: Montserrat;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    opacity: 0.8; 

    @media (max-width: 1024px){
        font-size: 18px;
        line-height:25px; 
    }
    
    @media (max-width: 414px){
        font-size: 15px;
        line-height: 22px; 
    }
`

export const ShowListItemGreen = styled.span`
    color: rgba(233, 71, 81, 1);
`

export const ShowListSmall = styled.ul`
    list-style-type: disc;
    padding-inline-start: 40px;

    @media (max-width: 414px){
        padding-inline-start: 15px;
    }
`

export const ShowListTitle = styled.div`
    color: #E94751;
    font-family: Palatino Linotype;
    font-size: 35px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 29px;
    opacity: 0.8;

    @media (max-width: 1024px){
        font-size: 25px;
        line-height: 30px; 
    }
    
    @media (max-width: 517px){
        font-size: 20px;
        line-height: 20px; 
        margin-bottom: 16px;

    }
`


export const ShowByeTitle= styled(ShowListTitle)`
    text-align: center;
    margin-bottom: 150px;
    color: #E94751;
    font-style: italic;
    opacity: 1;
`

export const OnlineShowTitle = styled.div`
  font-family: "AcontiumNapellus";
  line-height: 60px;
  font-size: 48px;
  text-align: center;
  color: #EE2152;
  margin-bottom: 40px;
  margin-top: -50px;

  @media (max-width: 1024px){
        line-height: 40px;
        font-size: 38px;
        margin-bottom: 23px;
        margin-top: 0;
    }
    
    @media (max-width: 414px){
        line-height: 28px;
        font-size: 24px;
        margin-top: -15px;
    }
`

export const MarketPart = styled.div`
  margin-top: 65px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
    // display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 45px;

  @media (max-width: 1400px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 1024px) {
    margin: 0;
    margin-top: 25px;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > a {
      overflow: hidden;
    }
  }

  @media (max-width: 577px) {
    margin: 0;
    margin-top: 25px;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 35px;


    & > a {
      overflow: hidden;
    }
  }
`

export const EnByeWrapper = styled.div`
  font-size: 28px;
  line-height: 38px;
  font-weight: 400;
  font-family: Palatino Linotype;
  text-align: center;
  margin-bottom: 140px;

  @media (max-width: 577px) {
    font-size: 20px;
    line-height: 25px;
  }
`

export const EnByeSpecial = styled.span`
    color #E94751;
    font-weight: 700;
`