import { useTranslation } from 'react-i18next'
import {Root, BannerBear, MainText, TitleWrapper, Title,MarketPart
} from './styles'; 
import axios from '../../utils/axios'
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import LoaderCircle from '../../ui-kit/loader-circle/loader-circle';
import CardPreview from '../Card/card-preview';
import { selectIsAuth } from '../../redux/slices/auth';
import defaultToy from './../../assets/defaultToy.png'
import defaultAvatar from './../../assets/cardPhoto.png'



const SaleWorks = () => {
    const { t, i18n } = useTranslation();
    const isAuth = useSelector(selectIsAuth)
    const [cards, setCards] = useState([])
    const [loading, setLoading] = useState(true);
    const sectionRef = useRef(null);

    const fetchCards = useCallback(
        async () => {
            try {
                const { data } = await axios.get(`/market`, { params: {
                    limit: 100, 
                    salepage: true
                }})
                setCards(data.items)
                setLoading(false)
            } catch (error) {
                console.log(error)
            }
        }, []
    )

    useEffect(() =>{
        fetchCards()
    }, [fetchCards])

    return (
        <Root>
            <BannerBear></BannerBear>
            <Title>BIG SALE IS OPEN!</Title>
            <MainText>
                {i18n.language === 'ru' && 
                    <TitleWrapper>
                        Распродажа продлится до 31 Марта 10:00pm по нью-йоркскому времени. Успейте купить авторские игрушки и куклы со скидками до 50% и более!
                    </TitleWrapper>
                }
                {i18n.language === 'en' && 
                    <TitleWrapper>
                        The sale will last until March 31 at 10:00pm New York time. Hurry to buy authors toys and dolls with discounts of up to 50% and more!
                    </TitleWrapper>
                }
            </MainText>
            {loading ? 
                <LoaderCircle/> : 
                <>
                    <MarketPart ref={sectionRef}>
                        {cards?.map((obj) => {
                            return (  
                                <CardPreview 
                                    onlineShow
                                    key={obj._id}
                                    altWork={obj.name} 
                                    imageWork={obj.imgUrl ?`https://toobears.com/api/${obj.imgUrl[0]}` : defaultToy}
                                    avatar={`https://toobears.com/api/${obj.author.avatarUrl}` || defaultAvatar}
                                    shopName={obj.author.shopname || `${obj.author.username} ${obj.author.surname}`}
                                    nameWork={obj.name}
                                    price={obj.price + ' ' + obj.currency}
                                    size={obj.size + 'cm'}
                                    likeActive={obj.like.includes(isAuth && isAuth._id)}
                                    status={obj.status}  
                                    link={`/card/${obj._id}`}  
                                    discount={obj.discount}
                                    sale
                                    discountEndedAt={obj.discountEndedAt}
                                    isSale
                                />
                        )})} 
                    </MarketPart>
                </>
            }
        </Root>
    )
}

export default SaleWorks;