import { useTranslation } from 'react-i18next'
import {Root, BannerBear, MainText, 
     MainTextTitle, 
      ShowByeTitle, Title, GreetingTitle, EnByeWrapper, EnByeSpecial
} from './styles';
import Rules from './blocks/rules';


const Sale = () => {
    const { t, i18n } = useTranslation();

    return (
        <Root>
            <BannerBear></BannerBear>
            <Title>BIG SALE!</Title>
            <GreetingTitle>{t('SaleDate')}</GreetingTitle>
            <MainText>
                {t('saleTitle')}
            </MainText>
            <Rules/>
            {i18n.language === "en" &&  
                <EnByeWrapper>
                    <div>Authors toys and dolls are little stories that can become part of your life.</div>
                    <div>Don't miss the opportunity to buy something truly <EnByeSpecial><i>special</i></EnByeSpecial>!</div>
                </EnByeWrapper>
            }
            {i18n.language === "ru" &&  
                <EnByeWrapper>
                    <div>Авторские игрушки и куклы — это маленькие истории, которые могут</div>
                    <div>стать частью вашей жизни. Не упустите возможность приобрести что-</div>
                    <div>то по-настоящему <EnByeSpecial><i>особенное    </i></EnByeSpecial>!</div>
                </EnByeWrapper>
            }
        </Root>
    )
}

export default Sale;