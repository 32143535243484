import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import heart from './assets/heart.png'
import { getCostWithDiscount  } from '../../utils/getCostWithDiscount'
import { compareDate } from '../../utils/compareDate'

const Root = styled(Link)`
    // figma design
    /* --card-name: rgba(0, 0, 0, 0.7);
    --card-price-for-sale: rgba(216, 148, 107, 1);
    --card-price-sold: rgba(0, 0, 0, 0.7);
    --card-price-reserved: rgba(61, 72, 139, 0.6);
    --card-size: rgba(0, 0, 0, 0.7); */

    // current
    --card-name: rgb(145, 114, 114);
    --card-price-for-sale: rgba(216, 148, 107, 1);
    --card-price-sold: rgba(0, 0, 0, 0.7);
    --card-price-reserved: rgba(61, 72, 139, 0.8);
    --card-size: rgba(0, 0, 0, 0.7);
    --card-shop-name: rgba(0, 0, 0, 0.7);


    box-sizing: border-box;
    padding: 14px;  
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid rgba(171, 154, 144, 0.26);
    border-radius: 10px;
    cursor: pointer;
    height: 100%;
    width: 100%;
    max-width: 310px;
    transition: all 0.5s ease-in-out;

    .hoverPart {
        display: flex;
        opacity: 0;
    }

    &:hover {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        .hoverPart {
            display: flex;
            opacity: 1;
            transition: all 0.5s ease-in-out;
        }
    }

    @media (max-width: 1024px){
        &:hover {
            .hoverPart {
                display: none;
            }
        }
    }

    @media (max-width: 414px){
        padding: 9px;  
    }
`

const ImageBox = styled.div`
    flex: 1 1 auto;
    position: relative;
`

const ImageWork = styled.img`
    width: 100%;
    height: 360px;
    object-fit: cover;

    @media (max-width: 1024px){
        height: ${props => props.onlineShow ? 'auto' : '220px'};
        ${props => props.onlineShow ? 'min-height: 390px' : ''};
    }
`

const InfoBox = styled.div`
    padding-top: 12px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    @media (max-width: 1024px){
        grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 414px){
        display: none;
        flex: 0 0 auto;
        padding-top: 1px;
    }
`

const InfoBoxMobile = styled.div`
    display: none;

    @media (max-width: 414px){
        padding-top: 12px;
        grid-template-columns:  ${props => props.sale ? ' 1fr 1fr 1fr' : ' 1fr 1fr'};
        display: grid;
        flex: 0 0 auto;
        padding-top: 1px;
    }
`

const InfoItem = styled.div`
    font-family: 'Montserrat';
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;

    @media (max-width: 414px){
        line-height: 14px;
    }
`

const InfoName = styled(InfoItem)`
    color: var( --card-name);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media (max-width: 414px){
        font-size: ${props => props.onlineShow ? '16px' : '11px'};
        margin: ${props => props.onlineShow ? '1px 0 1px' : '0'};
        line-height: ${props => props.onlineShow ? '17px' : '14px'};
    }
`

const InfoPrice = styled(InfoItem)`
    text-transform: ${props => props.status === '2' || props.status === '3' ? 'lowercase' : 'capitalize'};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    color: ${props => props.status === '1' ? 'var(--card-price-for-sale)' : props.status === '2' ? 'var(--card-price-sold)' : 'var(--card-price-reserved)'};
    
    @media (max-width: 1024px){
        text-align: end;
    }

    @media (max-width: 414px){
        font-size: ${props => props.onlineShow ? '16px' : '11px'};
        font-weight: ${props => props.onlineShow ? '600' : '500'};
        margin-top: ${props => props.onlineShow ? '3px' : '0'};
        text-align: ${props => props.sale ? 'center' : 'end'};
    }
`


const InfoSize = styled(InfoItem)`
    color: var(--card-size);
    text-align: end;
    text-decoration:  ${props => props.isSale ? 'line-through' : 'none'};

    @media (max-width: 1024px){
        display: none;
    }

    @media (max-width: 414px){
        display: block;
        opacity: ${props => props.onlineShow ? '0' : '1'};
        font-size: 10px;
    }
`

const InfoShop = styled(InfoItem)`
    color: var( --card-shop-name);
    display: none;

    @media (max-width: 414px){
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: ${props => props.onlineShow ? '13px' : '10px'};
        margin-top: ${props => props.onlineShow ? '3px' : '0'};
    }
`

const InfoFullPrice = styled(InfoShop)`
    text-decoration: line-through;
    text-align: end;
    font-size: 15px;
    font-weight: 400;
    opacity: 0.9;
`

const HoverShop = styled.div`
    padding: 6px 15px 6px 3px;
    border-radius: 0px 20px 0px 0px;
    background: #FEFDFD;    
    display: flex;
    align-items: center;
    max-width: 70%;
`

const HoverAvatar = styled.img`
    display: block;
    border-radius: 50px;
    margin-right: 6px;
    width: 28px;
    height: 28px;
    object-fit: cover;
`

const HoverShopName = styled.div`
    display: block;
    text-align: center;
    font-size: 16px;
    font-weight: 500; 
    color: var( --card-shop-name);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 1024px){
        font-size: 14px;
    }
`

const HoverLike = styled.div`
    padding: 8px 7px;
    border-radius: 20px 0px 0px 0px;
    background: #FEFDFD; 
    width: 33px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;

    .heart {
        width: 100px;
        height: 100px;
        background: url(${heart}) no-repeat;
        transition: background-position 1s steps(28);
        transition-duration: 0s;

        &.is-active {
        transition-duration: 1s;
        background-position: -2800px 0;
        }
    }

    @media (max-width: 414px){
        .heart {
            display: none;
        }
    }
`

const HoverPart = styled.div`
    position: absolute;
    justify-content: space-between;
    bottom: 0;
    width: 100%;
`

const CardPreview = (props) => {
    const { t } = useTranslation();

    const usualPrice = props.status === '1' ? props.price : props.status === '2' ? t('sold') : t('reserve');
    // const clearPrice = props.price.split(' ')[0]
    // const currencyPrice = props.price.split(' ')[1]
    // const getCostWithDiscount =  Number(clearPrice) - (Number(clearPrice) * Number(props.discount) / 100);
    // const getPriceWithDiscount = String(getCostWithDiscount) + ' ' + currencyPrice
    const priceWithDiscount = getCostWithDiscount(props.price, props.discount, true)
    const inSale = compareDate(props.discountEndedAt)
    const price =  inSale && props.discount && props.status === '1' ? priceWithDiscount : usualPrice

    return (
        <Root to={props.link}>
            <ImageBox>
                <ImageWork onlineShow={props.onlineShow} alt={props.altWork} src={props.imageWork}/>
                <HoverPart className='hoverPart'>
                    <HoverShop>
                        <HoverAvatar alt={props.shopName} src={props.avatar}/>
                        <HoverShopName>{props.shopName}</HoverShopName>
                    </HoverShop>
                    <HoverLike>
                        <div className='placement'>
                            <div className={props.likeActive ? 'heart is-active' : 'heart'}/>
                        </div>
                    </HoverLike>
                </HoverPart>
            </ImageBox>
            <InfoBox>
                <InfoName>{props.nameWork}</InfoName>
                <InfoPrice status={props.status}>
                    {price}
                </InfoPrice>
                <InfoShop>{props.shopName}</InfoShop>
                <InfoSize isSale={props.isSale}>{props.discount ? props.price : props.size}</InfoSize>
            </InfoBox>
            <InfoBoxMobile sale={props.sale}>
                {
                    props.sale ?
                        (<>
                            <InfoShop onlineShow={props.onlineShow}>{props.shopName}</InfoShop>
                            <InfoSize onlineShow={props.onlineShow}>{props.discount ? props.price : props.size}</InfoSize>
                            <InfoSize onlineShow={props.onlineShow}></InfoSize>
                            <InfoName onlineShow={props.onlineShow}>{props.nameWork}</InfoName>
                            <InfoPrice sale onlineShow={props.onlineShow} status={props.status}>
                                {price}
                            </InfoPrice>
                            <InfoFullPrice onlineShow={props.onlineShow} status={props.status}>
                                {props.status === '1' ? props.price : props.status === '2' ? t('sold') : t('reserve')}
                            </InfoFullPrice>
                        </>)
                     : 
                        (<>
                            <InfoName onlineShow={props.onlineShow}>{props.nameWork}</InfoName>
                            <InfoSize onlineShow={props.onlineShow}>{props.size}</InfoSize>
                            <InfoShop onlineShow={props.onlineShow}>{props.shopName}</InfoShop>
                            <InfoPrice onlineShow={props.onlineShow} status={props.status}>
                                {props.status === '1' ? price : props.status === '2' ? t('sold') : t('reserve')}
                            </InfoPrice>
                        </>)
                }
            </InfoBoxMobile>
        </Root>
    )
}

export default CardPreview;