import { useTranslation } from 'react-i18next'
import {ShowListTitle, ShowList, ShowListItem } from '../styles';

const Rules = () => {
    const { t, i18n } = useTranslation();

    return (
      <>
        <ShowListTitle><i>{t('ShowRules')}</i></ShowListTitle>
        <ShowList>
            <ShowListItem><i><b>{t('saleBegins')}</b></i> {t('saleItem1')}</ShowListItem>
            <ShowListItem>
            {i18n.language === "ru" && 
              <>
                Допускаются к участию <b>любые работы</b> соответствующие правилам сайта.
              </>
            }
            {i18n.language === "en" && 
              <>
                <b>Any works </b>that comply with the site rules are allowed to participate. 
              </>
            }
            </ShowListItem>
            <ShowListItem>{t('saleItem3')}</ShowListItem>
            <ShowListItem><i><b>{t('saleItem4/1')}</b></i>{t('saleItem4/2')}</ShowListItem>
            <ShowListItem>{t('saleItem5/1')}</ShowListItem>
            <ShowListItem>{t('saleItem6')}</ShowListItem>
            <ShowListItem>{t('saleItem7')}</ShowListItem>
            <ShowListItem>{t('saleItem8')}</ShowListItem>
            <ShowListItem>{t('saleItem9')}</ShowListItem>
            <ShowListItem>{t('saleItem10')}</ShowListItem>
            <ShowListItem>{t('saleItem11')}</ShowListItem>
            <ShowListItem>{t('saleItem12')}</ShowListItem>
            {/* <ShowListSmall>
                <ShowListItem>{t('ShowItemSmall1')}</ShowListItem>
                <ShowListItem>{t('ShowItemSmall2')}</ShowListItem>
                <ShowListItem>{t('ShowItemSmall3')}</ShowListItem>
                <ShowListItem>{t('ShowItemSmall4')}</ShowListItem>
                <ShowListItem>{t('ShowItemSmall5')}</ShowListItem>
            </ShowListSmall> */}
        </ShowList>
      </> 
    )
}

export default Rules;